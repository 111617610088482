import React from "react";

const Navbar = () => {
  return (
    <header>
      <h1 className="header-text1">Jobcloud todo</h1>
    </header>
  );
};

export default Navbar;
