import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  tab: {
    fontSize: 'md',
  },
  tabpanel: {
    p: 0,
  },
});

// define custom variants
const colorfulVariant = {
  tab: {
    bg: '#E7EFF1',
    borderTopRadius: '24px',
    borderBottom: 'none',
    boxShadow: 'inset -2px -2px 6px rgba(0, 39, 46, 0.05)',
    _selected: {
      bg: 'teal.50',
      boxShadow: '2px -4px 6px rgba(0, 11, 72, 0.03), -2px -4px 6px rgba(0, 11, 72, 0.03)',
      color: 'teal.600',
    },
  },
};

const variants = {
  colorful: colorfulVariant,
};

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  variant: 'colorful',
  colorScheme: 'teal',
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps,
});