import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import theme from "./theme";

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AmplifyProvider theme={theme}>
    <App />
  </AmplifyProvider>
);
