import { extendTheme } from '@chakra-ui/react'
import { tabsTheme } from './Tabs';

const theme = extendTheme({
  colors: {
    teal: {
      50: '#F3F9FA',
      100: '#CCE6EB',
      200: '#99CDD7',
      300: '#66B4C2',
      400: '#339BAE',
      500: '#00829A',
      600: '#00687B',
      700: '#004E5C',
      800: '#00343E',
      900: '#00272E',
    }
  },
  fonts: {
    body: `'Inter', sans-serif`,
    heading: `'Inter', sans-serif`,
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'normal',
      },
      variants: {
        solid: {
          bg: 'teal.600',
          borderRadius: '24px'
        },
      },
      defaultProps: {
        size: 'lg',
        colorScheme: 'teal',
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: 'white',
            borderColor: 'teal.200',
            _focus: {
              borderColor: 'teal.600',
            },
            _focusVisible: {
              borderColor: 'teal.600',
            }
          }
        }
      }
    },
    Link: {
      variants: {
        primary: ({ colorScheme = "teal" }) => ({
          color: `${colorScheme}.600`,
          _hover: {
            color: `${colorScheme}.400`,
          },
        }),
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: 'white',
            _focus: {
              borderColor: 'teal.600',
            },
            _focuVisibles: {
              borderColor: 'teal.600',
            }
          }
        }
      }
    },
    Tabs: tabsTheme,
  },
  styles: {
    global: {
      body: {
        bg: 'teal.50',
        color: 'teal.900',
      },
    },
  },
});

export default theme;