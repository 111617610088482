const apiEndpoint = process.env.REACT_APP_API_GATEWAY;

export const getTodo = async () => {
  try {
    const response = await fetch(apiEndpoint);
    if (!response.ok) {
      throw new Error("oops somenting went wrong!");
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const postTodo = async (todo) => {
  if (todo.todo.trim().length === "") {
    return "Todo description cannot be empty";
  }
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify(todo),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteTodo = async (todoToDelete) => {
  try {
    const response = await fetch(apiEndpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID: todoToDelete.ID,
        todo: todoToDelete.todo,
      }),
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const patchTodo = async (todoToUpdate) => {
  try {
    const response = await fetch(apiEndpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID: todoToUpdate.ID,
        todo: todoToUpdate.todo,
        isDone: todoToUpdate.isDone,
      }),
    });

    return response;
  } catch (error) {
    return error;
  }
};
